/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
/* eslint-disable tailwindcss/no-custom-classname */
import * as React from "react";
import { ProductContainer, SmartLink } from "@src/components";
import "swiper/css";
import "swiper/css/pagination";

export type ProductCtaModalProps = {
  id?: string | null;
  heading?: string | null;
  subheading?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text?.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductCtaModal({
  id = "",
  heading,
  subheading,
  ctaTitle,
  ctaUrl,
  ctaStyle
}: ProductCtaModalProps) {
  const defaultCtaStyle: string = "red";
  const ctaClassNames: { [key: string]: string } = {
    red: "pr-button-red",
    green: "bg-pr-green-500 text-white",
    yellow: "bg-pr-yellow-500 text-white",
    teal: "bg-pr-teal-500 text-white",
    black: "pr-button-black"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <div className="pb-8 pt-[60px] md:py-32">
      <ProductContainer>
        <div className="space-y-4">
          <div className="mx-auto max-w-[812px] space-y-4 text-center">
            {heading && (
              <h2 className="type-pr-beta">{returnLineBreaks(heading)}</h2>
            )}
            {subheading && (
              <div className=" text-pr-2xl text-pr-dark-gray-400 md:text-pr-4xl">
                {subheading}
              </div>
            )}
          </div>
        </div>

        {ctaUrl && ctaTitle && (
          <div className="pt-10 text-center">
            <SmartLink
              href={ctaUrl}
              className={`pr-button pr-button-lg ${ctaClassName}`}
            >
              {ctaTitle}
            </SmartLink>
          </div>
        )}
      </ProductContainer>
    </div>
  );
}

export default ProductCtaModal;
